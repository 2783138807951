import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"

import DesignCirclePartImage from "../images/mask@3x.png"
import AppDevThumbnail from "../components/thumbnails/AppDevThumbnail"
import SoftDevThumbnail from "../components/thumbnails/SoftDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"
import DesignCornerImage from "../images/rectangle-md.svg"
import DesignRectangle from "../images/greener-square.svg"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  challengeTitle,
  challengeDescription,
  challengeDescription2,
  challengeImage,
  solutionTitle,
  solutionDescription,
  solutionImage,
  solutionImage2,
  solutionItem,
  solutionItem2,
  solutionItemDescription,
  solutionItemDescription2,
  solutionSubTitle,
  solutionSubTitle2,
  solutionSubTitleDescription,
  solutionSubTitleDescription2,
  resultDescription,
  resultImage,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK*/}
      <div className="relative lg:flex container mx-auto py-24 pb-12 px-10 lg:px-32">
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 transform rotate-180 hidden md:block"
          style={{ left: "35%", top: "5px" }}
        />
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-48 pt-6 right-8 bottom-4 hidden md:block"
        />
        <div className="lg:w-1/3">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="mt-10 pb-8 md:pb-24">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
        </div>
        <div className="lg:w-2/3 mt-10 lg:mt-0 sm:w-3/4">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="mx-auto w-7/8"
          />
        </div>
      </div>

      {/* CHALLENGE BLOCK */}
      <div className="bg-gray-300">
        <div className="realative container mx-auto py-12 pt-16 px-16 md:px-10 lg:px-32">
          <img
            src={DesignRectangle}
            alt=""
            className="relative -left-10 top-9"
          />
          <h2 className="font-semibold text-3xl mb-12">{challengeTitle}</h2>
          <div className="md:flex md:flex-row pb-2">
            <div className="md:w-3/5 md:pr-16 mb-24 md:mb-0 items-center">
              <img src={challengeImage.childImageSharp.fixed.src} alt="" />
            </div>
            <div className="md:w-2/3 text-gray-500 md:pl-24">
              <div>
                <p className="pb-4">{challengeDescription}</p>
                <br />
                <p>{challengeDescription2}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SOLUTION BLOCK*/}
      <div className="container mx-auto py-12 pt-16 pb-0 px-16 md:px-10 lg:px-32">
        <div className="md:flex flex-row">
          <div className="flex flex-1">
            <div className="md:w-1/2">
              <img
                src={DesignRectangle}
                alt=""
                className="relative -left-10 top-17"
              />
              <h2 className="font-semibold text-3xl mb-12 pt-8">
                {solutionTitle}
              </h2>
              <p className="mb-10 text-gray-500 md:pr-12 md:pb-8">
                {solutionDescription}
              </p>
            </div>

            <div className="relative md:w-1/2 hidden md:block">
              <img
                src={solutionImage.childImageSharp.fixed.src}
                alt=""
                className="md:absolute right-1 top-8"
              />
              <img
                src={DesignCirclePartImage}
                alt=""
                className="absolute w-24 transform -rotate-90 -top-6 right-0"
              />
            </div>
          </div>
        </div>
      </div>

      {/* SOLUTION USER INFO BLOCK */}
      <div className="bg-gray-900">
        <div className="container mx-auto py-24 md:pt-32 px-16 md:px-10 lg:px-32 md:flex">
          <div className="md:pr-6 relative">
            <img
              src={DesignCornerImage}
              alt=""
              className="absolute w-8 md:w-12 -left-10 -top-10"
            />
            <h3 className="font-semibold text-white mb-4">{solutionItem}</h3>
            <p className="text-white mb-4 md:pr-12">
              {solutionItemDescription}
            </p>
          </div>
          <div className="md:pl-4">
            <h3 className="font-semibold text-white mb-4">{solutionItem2}</h3>
            <p className="text-white mb-4">{solutionItemDescription2}</p>
          </div>
        </div>
      </div>

      {/* ADITIONAL INFO BLOCK */}
      <div className="container mx-auto py-4 px-16 md:px-10 lg:px-32 md:flex">
        <div className="sm:w-1/2 lg:w-1/2 pr-4 py-16">
          <div>
            <h2 className="font-semibold text-gray-500 mb-4">
              {solutionSubTitle}
            </h2>
            <h4 className="text-gray-500 mb-4 md:pr-6">
              {solutionSubTitleDescription}
            </h4>
            <h2 className="font-semibold text-gray-500 mb-4 md:pr-8">
              {solutionSubTitle2}
            </h2>
            <ul className="text-gray-500 list-default leading-9 pl-8">
              {solutionSubTitleDescription2.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="relative sm:w-2/3 lg:w-2/3 pl-4 pt-6 hidden md:block">
          <img
            src={solutionImage2.childImageSharp.fixed.src}
            alt=""
            className="md:absolute lg:right-auto m-0 pl-32"
          />
        </div>
      </div>

      {/* RESULT BLOCK */}
      <div className="bg-gray-300">
        <div className="container mx-auto pt-5 px-16 md:px-10 lg:px-32">
          <div className="md:flex flex-row">
            <div className="relative flex-1 md:pr-8">
              <img
                src={resultImage.childImageSharp.fixed.src}
                alt=""
                className="md:max-h-96 md:absolute w-7/8 md:-top-10 hidden md:block"
              />
            </div>
            <div className="md:w-1/2 text-gray-500 py-12 md:px-10 md:py-24 md:pl-24 flex-1 relative">
              <p className="">{resultDescription}</p>
              <img
                src={DesignCirclePartImage}
                alt=""
                className="absolute w-48 md:pt-5 px-10 -right-16 bottom-8 hidden md:block"
              />
            </div>
          </div>
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <div className="container mx-auto py-32 px-10 lg:px-32">
        <h3 className="text-center font-semibold text-2xl mb-12">
          <FormattedMessage id="case_study.tech_used" values={{ app: title }} />
        </h3>
        <div className="md:flex md:flex-row justify-center">
          <AppDevThumbnail className="md:w-1/3" />
          <SoftDevThumbnail className="md:w-1/3" />
          <UiUxThumbnail className="md:w-1/3" />
        </div>
      </div>
    </div>
  )
}

const FoodUninonDriver = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{project.frontmatter.title}</title>
            <meta
              name="description"
              content={project.frontmatter.mainDescription}
            />
          </Helmet>
        }
        content={project.html}
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default FoodUninonDriver

export const pageQuery = graphql`
  query FoodUninonDriverID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeTitle
        challengeDescription
        challengeDescription2
        challengeImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionTitle
        solutionDescription
        solutionImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionImage2 {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionItem
        solutionItem2
        solutionItemDescription
        solutionItemDescription2
        solutionSubTitle
        solutionSubTitle2
        solutionSubTitleDescription
        solutionSubTitleDescription2
        resultDescription
        resultImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
